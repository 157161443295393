import React from 'react';
import { observer } from 'mobx-react';
import { Text } from '@consta/uikit/Text';
import store from 'client/store';
import { withRouter } from 'react-router-dom';
import { Navbar, NavbarRail } from '@consta/header/Navbar';
import { Collapse } from '@consta/uikit/Collapse';
import { observable } from 'mobx';
import { Badge } from '@consta/uikit/Badge';
import './ModuleMenu.scss';
import { t } from 'i18next';
import CaretUpIcon from '@phosphor-icons/core/regular/caret-up.svg';
import CaretDownIcon from '@phosphor-icons/core/regular/caret-down.svg';

const BADGE_STATUSES = {
	beta: {
		status: 'warning',
		label: 'beta',
	},
	deprecated: {
		status: 'alert',
		label: 'old',
	},
};

@withRouter
@observer
export class ModuleMenu extends React.Component {
	@observable open = false;
	items = [];

	constructor(props) {
		super(props);
		const menu = typeof this.props.module.menu === 'function' ? this.props.module.menu(store) : this.props.module.menu;
		this.items = menu.map((record) => new MenuItem(record));
		this.open = store.local.submenu[this.props.module.code];
		if (this.items.some((r) => this.getItemActive(r))) {
			this.open = true;
		}
		this.init();
	}

	init = () => {
		// Если указано событие по которому нужно обновлять badge подписываемся на него
		// когда это событие срабатывает, то вызываем обновление коунтера
		for (const item of this.items) {
			if (item.badgeEvent) {
				store.socket.on(item.badgeEvent, (data) => {
					this.update(item);
				});
				this.update(item);
			}
		}
	};

	componentWillUnmount() {
		for (const item of this.items) {
			store.socket.off(item.badgeEvent);
		}
	}

	update = async (item) => {
		if (item.badgeFunc) {
			item.count = await item.badgeFunc(store);
			this.forceUpdate();
		}
	};

	onItemClick = (item, { e }) => {
		e.preventDefault();
		if (item.path) {
			store.route.push({ path: item.path });
			store.local.lastPath = item.path;
		}
	};

	getItemActive = (item) => this.props.location.pathname.includes(item.path);

	toggleOpen = () => {
		if (!this.items.some((r) => this.getItemActive(r))) {
			this.open = !this.open;
			store.local.submenu[this.props.module.code] = this.open;
		}
	};

	getItemLabelNavbarRail = (item) => {
		return (
			<Text size='2xs' lineHeight='s'>
				{item.label} {item.count ? `(${item.count})` : ''}
			</Text>
		);
	};

	getItemRightSide = (item) => {
		const badges = [];
		if (item.type && ['beta', 'deprecated'].includes(item.type)) {
			badges.push(<Badge form='round' size='xs' {...BADGE_STATUSES[item.type]} />);
		}
		if (item.count) {
			badges.push(<Badge form='round' label={item.count} size='s' />);
		}
		return badges.length ? badges : null;
	};

	getItemStatus = (item) => (item.count ? 'normal' : null);

	getItemTooltip = (item) =>
		item.type && ['beta', 'deprecated'].includes(item.type) && <Badge form='round' size='s' {...BADGE_STATUSES[item.type]} />;

	render() {
		const { module } = this.props;
		if (!this.items.length) return null;

		const isFullMode = store.ui.menu;
		let titleModule = t('module.' + module.code + '.title');
		if (!isFullMode) {
			titleModule = titleModule.length <= 9 ? titleModule : titleModule.substr(0, 8) + '.';
		}

		let label = (
			<Text
				size={isFullMode ? 'xs' : '2xs'}
				transform='uppercase'
				weight={isFullMode ? 'bold' : 'semibold'}
				lineHeight={isFullMode ? undefined : 'xl'}
				align={isFullMode ? undefined : 'center'}
				truncate={!isFullMode}
			>
				{titleModule}
			</Text>
		);

		if (!isFullMode) {
			const Icon = this.open ? CaretUpIcon : CaretDownIcon;
			label = (
				<div className='module-menu-label'>
					{label}
					{!isFullMode && <Icon size='s' />}
				</div>
			);
		}

		const NavbarComponent = isFullMode ? Navbar : NavbarRail;

		return (
			<Collapse
				label={label}
				iconPosition={isFullMode ? 'right' : null}
				className='ModuleMenu'
				isOpen={this.open}
				onClick={this.toggleOpen}
				horizontalSpace={isFullMode ? 'm' : '2xs'}
				rightSide={module.type && isFullMode ? <Badge form='round' size='xs' {...BADGE_STATUSES[module.type]} /> : undefined}
			>
				<NavbarComponent
					items={this.items}
					onItemClick={this.onItemClick}
					getItemActive={this.getItemActive}
					getItemLabel={isFullMode ? undefined : this.getItemLabelNavbarRail}
					getItemRightSide={this.getItemRightSide}
					getItemStatus={isFullMode ? undefined : this.getItemStatus}
					size='s'
					getItemTooltip={this.getItemTooltip}
					getItemAs={() => 'a'}
					getItemAttributes={(item) => ({ href: item.path })}
				/>
			</Collapse>
		);
	}
}

const MenuItem = (data) => {
	return observable({ ...data, count: 0 });
};

